import Image from "next/image";
import Link from "next/link";
import { blurDataURL } from "../../../utils/constants/images";
import { NEWS_LIST_URL } from "../../../utils/constants/websiteUrls";
import Badge from "../../common/Badge";
import ClockIcon from "../../common/icons/ClockIcon";
import RedDividerH from "../../common/RedDividerH";

const SmallNewsCard = ({ article }) => {
  return (
    <div className="group w-full h-80 relative rounded-md overflow-hidden sm:aspect-w-2 sm:h-full transform transition duration-500 hover:scale-105">
      <Image
        src={article.thumbnail}
        alt="News Thumbnail"
        fill="true"
        quality={100}
        placeholder="blur"
        blurDataURL={blurDataURL}
        className="absolute object-cover sm:object-fill group-hover:opacity-75 sm:absolute sm:inset-0 sm:w-full sm:h-full"
      />

      <div
        aria-hidden="true"
        className="w-full h-80 bg-gradient-to-b from-transparent to-black opacity-100 absolute sm:absolute sm:inset-0"
      ></div>
      <div className="p-6 flex items-end absolute inset-0">
        {article.category && <Badge categoryName={article.category.name} />}
        <div>
          <RedDividerH />

          <h3 className="mt-2 mb-4 font-semibold text-white text-base lg:text-xl xl:text-xl 2xl:text-xl">
            <Link href={`${NEWS_LIST_URL}/${article.slug}`}>
              <span className="absolute inset-0"></span>
              {article.title}
            </Link>
          </h3>

          <span className="absolute bottom-3 left-[24px] text-center text-xs font-normal text-gray-400 break-words inline-flex justify-center items-center">
            <ClockIcon />
            {article.time_ago}
            <span
              className="h-3 w-0.5 bg-gray-400 mr-2 ml-2"
              aria-hidden="true"
            ></span>
            <span className="text-gray-400">
              {article.group === "UNDER20" || article.group === "UNDER17"
                ? "ACADEMY"
                : article.group}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SmallNewsCard;
