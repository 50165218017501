import { Fragment, useState } from "react";

import Image from "next/legacy/image";
import { blurDataURL } from "../../../utils/constants/images";

import { Dialog, Transition } from "@headlessui/react";

const ProductCard = ({ product }) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <>
      <a
        target="_blank"
        rel="noopener noreferrer"
        aria-label={product?.name}
        onClick={() => {
          openModal();
        }}
      >
        <div className="group relative transform transition duration-500 hover:scale-105">
          <div className="w-full h-56 rounded-md overflow-hidden group-hover:opacity-75 lg:h-72 xl:h-80">
            <div className="relative w-full h-full object-center object-contain bg-gray-300/10">
              <Image
                src={product?.image}
                alt="Product Image"
                layout="fill"
                quality={100}
                objectFit="contain"
                placeholder="blur"
                blurDataURL={blurDataURL}
              />
            </div>
          </div>
        </div>
      </a>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {}}
          open={isOpen}
        >
          <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Get your Kotoko Jerseys
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Kindly purchase your Kotoko home and away jerseys by
                      calling this number
                      <strong>
                        <a href="tel:0244653485"> 0244653485</a>
                      </strong>
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-start float-right rounded-md border border-transparent px-4 py-2 text-sm font-medium text-green-500 bg-green-100 hover:bg-green-200  focus:outline-none focus-visible:ring-2 focus-visible:ring-green-600  focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ProductCard;
