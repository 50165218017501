import React from "react";
import { useTimer } from "react-timer-hook";

const LiveMatchCountdownTimer = ({ expiryTimestamp }) => {
  const { seconds, minutes, hours, days, isRunning } = useTimer({
    expiryTimestamp,
    onExpire: () => {},
  });

  return (
    <>
      <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
    </>
  );
};

export default LiveMatchCountdownTimer;
