import Image from "next/image";
import Link from "next/link";
import { blurDataURL } from "../../../utils/constants/images";
import { NEWS_LIST_URL } from "../../../utils/constants/websiteUrls";
import ClockIcon from "../../common/icons/ClockIcon";
import RedDividerH from "../../common/RedDividerH";

function HeroNews({ article }) {
  return (
    <section className="relative h-[80vh] sm:h-[90vh] w-full bg-gradient-to-t from-black">
      <div
        className="w-full h-full absolute bg-gradient-to-b from-black via-black to-black opacity-[0.7]"
        aria-hidden="true"
      ></div>

      <Image
        src={article.thumbnail}
        alt="News Thumbnail"
        fill="true"
        quality={100}
        priority
        placeholder="blur"
        blurDataURL={blurDataURL}
        className="relative h-full w-full object-cover sm:object-fill mix-blend-overlay"
      />

      <div className="absolute flex flex-col space-y-2 md:space-y-4 lg:space-y-4 2xl:space-y-4 bottom-10 2xl:bottom-32 pl-8 pr-6 left-0 right-2 mb-24 md:mb-24 lg:mb-24 2xl:mb-32 2xl:pl-80">
        <RedDividerH />
        <h1 className="text-xl font-extrabold tracking-wide sm:tracking-normal sm:text-2xl lg:text-5xl 2xl:text-7xl text-white md:w-3/4 lg:w-3/4">
          {article.title}
        </h1>
        <p className="text-white md:w-3/4 lg:w-3/4 text-xs sm:text-sm lg:text-base 2xl:text-xl tracking-wide ">
          {article.caption}
        </p>
      </div>

      <div className="absolute bottom-20 mb-2 2xl:bottom-48 pl-4 2xl:pl-[300px]">
        <Link
          href={`${NEWS_LIST_URL}/${article.slug}`}
          className="bg-transparent inline-flex justify-start items-center text-center py-3 px-4 text-sm lg:text-base 2xl:text-xl font-medium text-red-600 hover:text-red-700 dark:text-green-800 dark:hover:text-green-700"
        >
          Continue reading
          <span className="pl-1" aria-hidden="true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="bi bi-arrow-right h-5 w-5 ml-1 inline-flex items-center justify-center"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </span>
        </Link>
      </div>

      <span className="absolute bottom-10 2xl:bottom-32 pl-8 2xl:pl-[315px] text-center text-xs font-normal text-gray-400 inline-flex justify-center items-center">
        <ClockIcon />
        {article.time_ago}
        <span
          className="h-3 w-0.5 bg-gray-400 mr-2 ml-2"
          aria-hidden="true"
        ></span>
        <span className="text-gray-400 first-letter:capitalize">
          {article.group === "UNDER20" || article.group === "UNDER17"
            ? "ACADEMY"
            : article.group}
        </span>
      </span>
    </section>
  );
}

export default HeroNews;
