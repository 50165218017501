import Link from "next/link";
import { GALLERIES_LIST_URL } from "../../../utils/constants/websiteUrls";
import RightArrowIcon from "../../common/icons/RightArrowIcon";
import GalleryListCard from "../gallery/GalleryListCard";

const RecentGallery = ({ galleries }) => {
  return (
    <section>
      <div className="max-w-7xl mx-auto pt-10 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-baseline sm:justify-between">
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
            Gallery
          </h2>
          <Link
            href={GALLERIES_LIST_URL}
            className="hidden text-sm font-semibold text-red-600 dark:text-green-800  hover:text-red-500 dark:hover:text-green-700 sm:block">
            Browse all galleries<span aria-hidden="true">
              <RightArrowIcon />
            </span>

          </Link>
        </div>

        <div className="mt-4 flow-root">
          <div className="-my-2">
            <div className="box-content py-2 relative h-[340px] overflow-x-auto overflow-y-hidden xl:overflow-visible  scroll-smooth">
              <div className="absolute min-w-screen-xl px-4 flex space-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:grid xl:grid-cols-5 xl:gap-x-8">
                {galleries.map((gallery) => {
                  return <GalleryListCard key={gallery.id} gallery={gallery} />;
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 sm:hidden">
          <Link
            href={GALLERIES_LIST_URL}
            className="block text-sm font-semibold text-red-600 dark:text-green-800  hover:text-red-500 dark:hover:text-green-700">
            Browse all galleries<span aria-hidden="true">
              <RightArrowIcon />
            </span>

          </Link>
        </div>
      </div>
    </section>
  );
};

export default RecentGallery;
