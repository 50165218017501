import Image from "next/legacy/image";
import { blurDataURL } from "../../../utils/constants/images";

const TeamLogo = ({ teamLogo }) => {
  return (
    <>
      <div className="relative h-16 w-16 sm:h-36 sm:w-36">
        <Image
          src={teamLogo}
          alt="Team Logo"
          layout="fill"
          objectFit="contain"
          quality={100}
          placeholder="blur"
          blurDataURL={blurDataURL}
        />
      </div>
    </>
  );
};

export default TeamLogo;
