import { useEffect, useState } from "react";

import Image from "next/legacy/image";
import classNames from "classnames";

import { blurDataURL } from "../../../utils/constants/images";
import { convertToShortTimeAndDate } from "../../../utils/helpers/dateAndTime";
import MatchHighlightsButton from "../../common/buttons/MatchHighlightsButton";
import MatchReviewButton from "../../common/buttons/MatchReviewButton";
import { LIVE_MATCH_URL } from "../../../utils/constants/websiteUrls";

const LastMatch = ({ lastMatch }) => {
  const {
    id,
    season,
    slug,
    home_team: homeTeam,
    away_team: awayTeam,
    home_team_logo: homeTeamLogo,
    away_team_logo: awayTeamLogo,
    competition,
    home_team_score: homeTeamScore,
    away_team_score: awayTeamScore,
    venue,
    highlight_url: highlightsUrl,
    date_and_time: dateAndTime,
    week_or_round_number: weekOrRoundNumber,
    is_live_match_created: isLiveMatchCreated,
  } = lastMatch;

  const [formattedDateTime, setFormattedDateTime] = useState("");

  useEffect(() => {
    setFormattedDateTime(convertToShortTimeAndDate(dateAndTime));
  }, [dateAndTime]);

  return (
    <div className="flex flex-col py-20 sm:py-20 md:py20 lg:py-0 xl:py-0 2xl:py-0 sm:p-4">
      <div className="flex justify-between text-sm text-green-100 dark:text-red-100 space-x-6">
        <p>Last Match - {formattedDateTime}</p>

        <p>
          {homeTeam === "Kotoko" || homeTeam === "Asante Kotoko"
            ? "Home"
            : "Away"}
        </p>
      </div>

      <div className="flex mt-5">
        <Image
          src={
            homeTeam === "Kotoko" || homeTeam === "Asante Kotoko"
              ? awayTeamLogo
              : homeTeamLogo
          }
          alt="Team Logo"
          width="96px"
          height="96px"
          quality={100}
          className="mr-5"
          placeholder="blur"
          blurDataURL={blurDataURL}
        />

        <div className="flex flex-col ml-2 sm:ml-4 items-start justify-center space-y-6">
          <p className="text-green-100 dark:text-red-100 divide-x-2">
            <span className="font-extrabold text-green-100 dark:text-red-100 pr-2">
              {homeTeamScore}
            </span>
            <span
              className={classNames(
                homeTeam === "Asante Kotoko" || homeTeam === "Kotoko"
                  ? "font-bold"
                  : "font-normal",
                "pl-2 text-green-100 dark:text-red-100"
              )}
            >
              {homeTeam}
            </span>
          </p>
          <p className="text-green-100 dark:text-red-100 divide-x-2">
            <span className="font-extrabold text-green-100 dark:text-red-100 pr-2">
              {awayTeamScore}
            </span>

            <span
              className={classNames(
                awayTeam === "Asante Kotoko" || awayTeam === "Kotoko"
                  ? "font-bold"
                  : "font-normal",
                "text-green-100 dark:text-red-100 pl-2"
              )}
            >
              {awayTeam}
            </span>
          </p>
        </div>
      </div>

      <div
        className={classNames(
          highlightsUrl ? "justify-between" : "justify-center",
          "text-[10px] flex flex-col sm:flex-row items-center text-black sm:space-x-6 mt-5"
        )}
      >
        {isLiveMatchCreated && (
          <MatchReviewButton matchReportUrl={`${LIVE_MATCH_URL}/${slug}`} />
        )}

        {highlightsUrl && (
          <MatchHighlightsButton highlightUrl={highlightsUrl} />
        )}
      </div>
    </div>
  );
};

export default LastMatch;
