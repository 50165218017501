const RedDividerH = () => {
  return (
    <div className="border-t-2 border-red-600 dark:border-green-800 w-14 pl-8"></div>
  );
};

export default RedDividerH;

export const DividerH = () => {
  return (
    <div className="border-t-2 border-red-600 dark:border-green-800 w-1 pl-8"></div>
  );
};

export const LiveMatchDividerH = () => {
  return <div className="border-t-2  border-gray-500 w-24 mt-2"></div>;
};
