import Link from "next/link";
import { NEWS_LIST_URL } from "../../../utils/constants/websiteUrls";
import RightArrowIcon from "../../common/icons/RightArrowIcon";
import LargeNewsCard from "../news/LargeNewsCard";
import SmallNewsCard from "../news/SmallNewsCard";

const RecentNews = ({ NewsList }) => {
  return <>
    {/* News Section */}
    <section>
      <div className="max-w-7xl mx-auto pt-10 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-baseline sm:justify-between">
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
            News
          </h2>

          <Link
            href={NEWS_LIST_URL}
            className="hidden text-sm font-semibold text-red-600 dark:text-green-800 hover:text-red-500 dark:hover:text-green-700 sm:block">
            Browse all news<span aria-hidden="true">
              <RightArrowIcon />
            </span>

          </Link>
        </div>

        <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
          <LargeNewsCard article={NewsList[0]} />

          <SmallNewsCard article={NewsList[1]} />

          <SmallNewsCard article={NewsList[2]} />
        </div>
        <div className="mt-6 sm:hidden">
          <Link
            href={NEWS_LIST_URL}
            className="block text-sm font-semibold text-red-600 dark:text-green-800 hover:text-red-500 dark:hover:text-green-700">
            Browse all news<span aria-hidden="true">
              <RightArrowIcon />
            </span>

          </Link>
        </div>
      </div>
    </section>
  </>;
};

export default RecentNews;
