import Image from "next/legacy/image";
import { blurDataURL } from "../../../utils/constants/images";

const GPLTable = ({ gplTable }) => {
  return (
    <div className="mt-8 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-green-700 dark:bg-red-600">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-white sm:pl-6"
                  >
                    Pos
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-white sm:pl-6"
                  >
                    Team
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-medium uppercase tracking-wide text-white"
                  >
                    P
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-medium uppercase tracking-wide text-white"
                  >
                    W
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-medium uppercase tracking-wide text-white"
                  >
                    D
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-medium uppercase tracking-wide text-white"
                  >
                    L
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-medium uppercase tracking-wide text-white"
                  >
                    GF
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-medium uppercase tracking-wide text-white"
                  >
                    GA
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-medium uppercase tracking-wide text-white"
                  >
                    GD
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs font-medium uppercase tracking-wide text-white"
                  >
                    PTS
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-green-700 dark:bg-red-600">
                {gplTable?.map((team, index) => {
                  return team.team === "Asante Kotoko" ||
                    team.team === "Kotoko" ||
                    team.team === "Asante Kotoko SC" ? (
                    <tr key={team?.id} className="bg-green-900 dark:bg-red-900">
                      <td className="whitespace-nowrap py-4 pl-1 sm:pl-2 pr-3 text-sm">
                        <div className="flex items-start">
                          <div className="ml-4 mr-4 text-white">
                            {team?.position === 1 ? (
                              <div className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 dark:bg-green-100 dark:text-green-800">
                                {team?.position}
                              </div>
                            ) : (
                              <>{team?.position}</>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <Image
                              src={team?.team_logo}
                              alt="Team Logo"
                              width="40px"
                              height="40px"
                              placeholder="blur"
                              blurDataURL={blurDataURL}
                            />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-white">
                              {team?.team}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        <div className="text-white">{team?.matches_played}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        {team?.matches_won}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        {team?.matches_drawn}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        {team?.matches_lost}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        {team?.goals_scored}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        {team?.goals_against}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        {team?.goal_difference}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        {team?.team_point}
                      </td>
                    </tr>
                  ) : (
                    <tr key={team?.id}>
                      <td className="whitespace-nowrap py-4 pl-1 sm:pl-2 pr-3 text-sm">
                        <div className="flex items-start">
                          <div className="ml-4 mr-4 text-white">
                            {team?.position === 1 ? (
                              <div className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 dark:bg-green-100 dark:text-green-800">
                                {team?.position}
                              </div>
                            ) : (
                              <>{team?.position}</>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <Image
                              src={team?.team_logo}
                              alt="Team Logo"
                              width="40px"
                              height="40px"
                              placeholder="blur"
                              blurDataURL={blurDataURL}
                            />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-white">
                              {team?.team}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        <div className="text-white">{team?.matches_played}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        {team?.matches_won}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        {team?.matches_drawn}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        {team?.matches_lost}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        {team?.goals_scored}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        {team?.goals_against}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        {team?.goal_difference}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-white">
                        {team?.team_point}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GPLTable;
