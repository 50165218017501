import { useEffect, useState } from "react";

import Image from "next/legacy/image";
import Link from "next/link";

import { blurDataURL } from "../../../utils/constants/images";
import { convertToShortTimeAndDate } from "../../../utils/helpers/dateAndTime";
import BuyTicketsButton from "../../common/buttons/BuyTicketsButton";

const NextMatch = ({ nextMatch, ticketWebsiteUrl }) => {
  const {
    id,
    season,
    slug,
    home_team: homeTeam,
    away_team: awayTeam,
    home_team_logo: homeTeamLogo,
    away_team_logo: awayTeamLogo,
    competition,
    venue,
    date_and_time: dateAndTime,
    week_or_round_number: weekOrRoundNumber,
    city,
  } = nextMatch;

  const [formattedDateTime, setFormattedDateTime] = useState("");

  useEffect(() => {
    setFormattedDateTime(convertToShortTimeAndDate(dateAndTime));
  }, [dateAndTime]);

  return (
    <div className="flex flex-col p-4">
      <div className="flex justify-between text-sm text-white space-x-6">
        <p>Next Match - {formattedDateTime}</p>
        <p className="text-white">
          {homeTeam === "Kotoko" || homeTeam === "Asante Kotoko"
            ? "Home"
            : "Away"}
        </p>
      </div>
      <div className="flex mt-5">
        <Image
          src={
            homeTeam === "Kotoko" || homeTeam === "Asante Kotoko"
              ? awayTeamLogo
              : homeTeamLogo
          }
          alt="Team Logo"
          width="96px"
          height="96px"
          quality={100}
          className="mr-5"
          placeholder="blur"
          blurDataURL={blurDataURL}
        />

        <div className="flex flex-col items-start ml-2 sm:ml-0 md:ml-2  sm:items-center sm:justify-center ">
          <h1 className="text-xl font-extrabold text-white">
            {homeTeam === "Kotoko" || homeTeam === "Asante Kotoko"
              ? awayTeam
              : homeTeam}
          </h1>
          <p className="text-white">{venue}</p>
        </div>
      </div>

      {homeTeam === "Kotoko" || homeTeam === "Asante Kotoko" ? (
        <BuyTicketsButton ticketWebsiteUrl={ticketWebsiteUrl} />
      ) : (
        <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-6 mt-20"></div>
      )}
    </div>
  );
};

export default NextMatch;
