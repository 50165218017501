import { Fragment, useState, useEffect } from "react";

import Link from "next/link";

import { Dialog, Transition } from "@headlessui/react";

const BuyTicketsButton = ({ ticketWebsiteUrl }) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-6 mt-5">
        <a
          target="_blank"
          rel="noopener noreferrer"
          role="button"
          className="inline-flex items-center px-4 py-3 border border-transparent text-sm sm:text-base font-medium rounded-md shadow-sm text-green-700 dark:text-red-600 bg-white hover:bg-green-50 dark:hover:bg-red-50 sm:px-8"
          onClick={() => {
            openModal();
          }}
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="currentColor"
            className="-ml-1 mr-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5453 0.845502C12.6778 -0.0435527 11.2713 -0.0435527 10.4038 0.845502L0.650637 10.8408C-0.216879 11.7299 -0.216879 13.1713 0.650637 14.0604L1.80729 15.2457C2.31149 15.7624 3.01925 15.67 3.44691 15.4094C3.63056 15.2976 3.84482 15.2332 4.07603 15.2332C4.75762 15.2332 5.31015 15.7994 5.31015 16.4979C5.31015 16.7349 5.24727 16.9545 5.13812 17.1426C4.88392 17.5809 4.79364 18.3062 5.29784 18.823L6.45462 20.0085C7.32214 20.8976 8.72863 20.8976 9.59616 20.0085L19.3493 10.0132C20.2169 9.12408 20.2169 7.68265 19.3493 6.79359L18.1926 5.60816C17.6883 5.09137 16.9805 5.18397 16.5528 5.44456C16.3692 5.55649 16.1549 5.62096 15.9235 5.62096C15.242 5.62096 14.6894 5.05471 14.6894 4.3562C14.6894 4.11918 14.7524 3.89955 14.8616 3.71131C15.1158 3.27304 15.2062 2.54759 14.702 2.03081L13.5453 0.845502Z"
              fill="currentColor"
            />
          </svg>
          Buy Tickets
        </a>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {}}
          open={isOpen}
        >
          <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Get your E-Ticket
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Kindly purchase your match tickets via the short-code
                      <strong> *711#</strong>
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-start float-right rounded-md border border-transparent px-4 py-2 text-sm font-medium text-green-500 bg-green-100 hover:bg-green-200  focus:outline-none focus-visible:ring-2 focus-visible:ring-green-600  focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default BuyTicketsButton;

export const BuyTicketsTextButton = ({ ticketWebsiteUrl }) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-6 mt-5 sm:mt-0">
        <a
          target="_blank"
          rel="noopener noreferrer"
          role="button"
          className="inline-flex items-center border border-transparent text-sm sm:text-base font-medium dark:text-green-700 text-red-600 dark:hover:text-green-500 hover:text-red-500"
          onClick={() => {
            openModal();
          }}
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="currentColor"
            className="-ml-1 mr-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5453 0.845502C12.6778 -0.0435527 11.2713 -0.0435527 10.4038 0.845502L0.650637 10.8408C-0.216879 11.7299 -0.216879 13.1713 0.650637 14.0604L1.80729 15.2457C2.31149 15.7624 3.01925 15.67 3.44691 15.4094C3.63056 15.2976 3.84482 15.2332 4.07603 15.2332C4.75762 15.2332 5.31015 15.7994 5.31015 16.4979C5.31015 16.7349 5.24727 16.9545 5.13812 17.1426C4.88392 17.5809 4.79364 18.3062 5.29784 18.823L6.45462 20.0085C7.32214 20.8976 8.72863 20.8976 9.59616 20.0085L19.3493 10.0132C20.2169 9.12408 20.2169 7.68265 19.3493 6.79359L18.1926 5.60816C17.6883 5.09137 16.9805 5.18397 16.5528 5.44456C16.3692 5.55649 16.1549 5.62096 15.9235 5.62096C15.242 5.62096 14.6894 5.05471 14.6894 4.3562C14.6894 4.11918 14.7524 3.89955 14.8616 3.71131C15.1158 3.27304 15.2062 2.54759 14.702 2.03081L13.5453 0.845502Z"
              fill="currentColor"
            />
          </svg>
          Buy Tickets
        </a>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {}}
          open={isOpen}
        >
          <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Get your E-Ticket
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Kindly purchase your match tickets via the short-code
                      <strong> *711#</strong>
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-start float-right rounded-md border border-transparent px-4 py-2 text-sm font-medium text-green-500 bg-green-100 hover:bg-green-200  focus:outline-none focus-visible:ring-2 focus-visible:ring-green-600  focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
