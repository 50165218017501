const TeamName = ({ teamName }) => {
  return (
    <>
      <h1 className="text-center text-white text-sm sm:text-base w-24 sm:w-40 truncate text-ellipsis">
        {teamName}
      </h1>
    </>
  );
};

export default TeamName;
