import Image from "next/image";
import Link from "next/link";
import { blurDataURL } from "../../../utils/constants/images";
import { GALLERIES_LIST_URL } from "../../../utils/constants/websiteUrls";
import Badge from "../../common/Badge";
import ClockIcon from "../../common/icons/ClockIcon";

const GalleryListCard = ({ gallery }) => {
  return (
    <Link
      href={`${GALLERIES_LIST_URL}/${gallery.slug}`}
      className="relative w-56 h-80 rounded-md p-6 flex flex-col overflow-hidden hover:opacity-75 xl:w-auto hover:shadow-lg transform transition duration-500 hover:scale-105"
    >
      {gallery.category && <Badge categoryName={gallery?.category.name} />}
      <Image
        src={gallery?.thumbnail}
        alt="Gallery Thumbnail"
        fill="true"
        quality={100}
        className="w-full h-full object-center object-cover rounded-md"
        placeholder="blur"
        blurDataURL={blurDataURL}
      />
      <span
        aria-hidden="true"
        className="absolute inset-x-0 bottom-0 h-[90%] bg-gradient-to-t from-black via-black opacity-[0.9] rounded-md"
      ></span>
      <span className="relative mt-auto mb-4 text-center text-base font-semibold text-white break-words ">
        {gallery?.title}
      </span>
      <span className="absolute bottom-3 left-3 text-center text-xs font-normal text-gray-400 break-words inline-flex justify-center items-center">
        <ClockIcon />
        {gallery?.time_ago}
        <span
          className="h-3 w-0.5 bg-gray-400 mr-2 ml-2"
          aria-hidden="true"
        ></span>
        <span className="text-gray-400">
          {gallery?.group === "UNDER20" || gallery?.group === "UNDER17"
            ? "ACADEMY"
            : gallery?.group}
        </span>
      </span>
    </Link>
  );
};

export default GalleryListCard;
