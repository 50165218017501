import React, { useState, useEffect } from "react";

import { useStopwatch } from "react-timer-hook";

const LiveMatchStopWatch = ({ minutesPlayed, restartTimer }) => {
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({
      offsetTimestamp: minutesPlayed,
      autoStart: true,
    });

  const [resetTime, setResetTime] = useState(false);

  //   useEffect(() => {
  //     const time = new Date(minutesPlayed);
  //     time.setSeconds(time.getSeconds());
  //     reset(time);
  //   }, [minutesPlayed]);

  return (
    <>
      <span>{minutes}</span>:<span>{seconds}</span>
    </>
  );
};

export default LiveMatchStopWatch;
