import { useEffect, useState } from "react";

import { ref } from "firebase/database";
import { useList } from "react-firebase-hooks/database";

import Image from "next/image";
import TeamLogo from "../fixtures/TeamLogo";
import TeamName from "../fixtures/TeamName";
import {
  convertTo24HrTime,
  convertToShortDate,
} from "../../../utils/helpers/dateAndTime";
import Link from "next/link";
import { LIVE_MATCH_URL } from "../../../utils/constants/websiteUrls";
import {
  FIRST_EXTRA_TIME,
  FIRST_HALF_KICK_OFF,
  kFT,
  kHT,
  kPK,
  LIVE_MATCH_FIXTURES_PATH,
  LIVE_MATCH_FULL_TIME_STATUS,
  LIVE_MATCH_HALF_TIME_STATUS,
  LIVE_MATCH_PENALTIES_STATUS,
  LIVE_MATCH_UPCOMING_STATUS,
  REAL_TIME_LIVE_MATCH_DATABASE,
  SECOND_EXTRA_TIME,
  SECOND_HALF_KICK_OFF,
} from "../../../utils/constants/names";
import { database } from "../../../utils/helpers/firebaseConfig";
import LiveMatchCountdownTimer from "../liveMatch/LiveMatchCountdownTimer";
import LiveMatchStopWatch from "../liveMatch/LiveMatchStopWatch";
import { GiStopwatch } from "react-icons/gi";

const IndexLiveMatchHero = ({ liveMatchInfo, liveMatchImage }) => {
  const [fixtureTime, setFixtureTime] = useState("");
  const [fixtureDate, setFixtureDate] = useState("");

  useEffect(() => {
    setFixtureTime(convertTo24HrTime(liveMatchInfo.date_and_time));
    setFixtureDate(convertToShortDate(liveMatchInfo.date_and_time));
  }, [liveMatchInfo.date_and_time]);

  const liveMatchFixtureInfo = ref(
    database,
    `${REAL_TIME_LIVE_MATCH_DATABASE}/${LIVE_MATCH_FIXTURES_PATH}/fixture_${liveMatchInfo.id}`
  );

  const [fixtureInfoSnapshots, fixtureInfoLoading, fixtureInfoError] =
    useList(liveMatchFixtureInfo);

  const liveMatchFixtureStatus = fixtureInfoSnapshots[12]?.val();
  const fixtureCompetitionStatus = fixtureInfoSnapshots[10]?.val();
  const liveMatchHomeTeamScore = fixtureInfoSnapshots[19]?.val();
  const liveMatchAwayTeamScore = fixtureInfoSnapshots[5]?.val();
  const fixtureMinutePlayed = fixtureInfoSnapshots[23]?.val();
  const fixtureDateTime = liveMatchInfo.date_and_time;

  const countdownTime = new Date(fixtureDateTime);
  countdownTime.setSeconds(countdownTime.getSeconds() + 60);

  return (
    <>
      <section className="relative h-[80vh] sm:h-[90vh] w-full ">
        <div className="absolute h-full w-full mx-auto object-cover mix-blend-multiply">
          <Image
            src={liveMatchImage}
            alt="Baba Yara Stadium"
            fill="true"
            priority
            className="relative h-full w-full object-cover sm:object-fill mix-blend-overlay"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-red-800 dark:from-green-800 to-red-700 dark:to-green-700 mix-blend-multiply"></div>
        </div>

        <div className="absolute flex flex-col justify-center items-center inset-0 mr-10 ml-10 space-y-8 sm:space-y-4 md:space-y-10 lg:space-y-10 xl:space-y-5 2xl:space-y-10">
          {fixtureInfoLoading ? (
            <></>
          ) : liveMatchFixtureStatus === LIVE_MATCH_FULL_TIME_STATUS ||
            liveMatchFixtureStatus === LIVE_MATCH_UPCOMING_STATUS ? (
            <></>
          ) : (
            <>
              <div className="flex items-center space-x-2 text-green-400 dark:text-red-400 mt-5">
                <span className="relative w-2 h-2 rounded-full bg-green-400 dark:bg-red-400">
                  <span className="absolute w-3 h-3 rounded-full -left-1 -top-1 animate-ping bg-green-400 dark:bg-red-400"></span>
                </span>
                <span className="text-sm font-bold">Live</span>
              </div>
            </>
          )}

          <div className="flex flex-col justify-center items-center mx-auto mt-10">
            <p className="text-white text-xs w-[70vw] truncate text-ellipsis text-center">
              {liveMatchInfo.competition}
            </p>
            <p className="text-red-300 dark:text-green-300 text-xs mb-2 w-[70vw] truncate text-ellipsis text-center">
              {liveMatchInfo.week_or_round_number}
            </p>
            <p className="text-gray-300 text-sm font-bold w-[70vw] truncate text-ellipsis text-center">
              {liveMatchFixtureStatus === LIVE_MATCH_FULL_TIME_STATUS &&
              fixtureCompetitionStatus !== "" ? (
                fixtureCompetitionStatus
              ) : fixtureInfoLoading ? (
                <></>
              ) : (
                <>
                  {fixtureTime},{" "}
                  <span className="text-gray-400">{fixtureDate}</span>
                </>
              )}
            </p>
          </div>

          <div className="flex justify-between items-center space-x-6">
            <div className="flex flex-col justify-center items-center space-y-2">
              <TeamLogo teamLogo={liveMatchInfo.home_team_logo} />
              <TeamName teamName={liveMatchInfo.home_team} />
            </div>

            {liveMatchFixtureStatus === LIVE_MATCH_UPCOMING_STATUS ? (
              <>
                <div className="flex flex-col justify-center items-center space-y-2 w-16 sm:w-40 truncate text-ellipsis text-center">
                  <p className="text-red-300 dark:text-green-300 font-semibold text-xl sm:text-4xl">
                    VS
                  </p>

                  <p className="text-gray-50 font-semibold text-xs sm:text-lg">
                    <LiveMatchCountdownTimer expiryTimestamp={countdownTime} />
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="flex justify-between items-center w-28 sm:w-40 truncate text-ellipsis text-center space-x-1">
                  {/*  Show this if game is full Time */}
                  <p className="text-white font-semibold text-3xl sm:text-6xl">
                    {liveMatchHomeTeamScore}
                  </p>

                  {/* <!-- Show this if game is on-going--> */}
                  {liveMatchFixtureStatus === FIRST_HALF_KICK_OFF ||
                  liveMatchFixtureStatus === FIRST_EXTRA_TIME ||
                  liveMatchFixtureStatus === SECOND_HALF_KICK_OFF ||
                  liveMatchFixtureStatus === SECOND_EXTRA_TIME ? (
                    <p className="text-red-300 dark:text-green-300 animate-pulse">
                      {fixtureMinutePlayed}
                    </p>
                  ) : (
                    <p className="text-red-300 dark:text-green-300 font-semibold">
                      {liveMatchFixtureStatus ===
                      LIVE_MATCH_HALF_TIME_STATUS ? (
                        kHT
                      ) : liveMatchFixtureStatus ===
                        LIVE_MATCH_FULL_TIME_STATUS ? (
                        kFT
                      ) : liveMatchFixtureStatus ===
                        LIVE_MATCH_PENALTIES_STATUS ? (
                        kPK
                      ) : (
                        <GiStopwatch size={24} color="#a3a3a3" />
                      )}
                    </p>
                  )}

                  <p className="text-white font-semibold text-3xl sm:text-6xl">
                    {liveMatchAwayTeamScore}
                  </p>
                </div>
              </>
            )}

            <div className="flex flex-col justify-center items-center space-y-2">
              <TeamLogo teamLogo={liveMatchInfo.away_team_logo} />
              <TeamName teamName={liveMatchInfo.away_team} />
            </div>
          </div>

          <div className="inline-flex text-red-300 dark:text-green-300 text-xs sm:text-sm pb-4  w-[70vw] justify-center">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="-ml-1 mr-2 h-4 w-4 sm:h-5 sm:w-5"
                fill="currentColor"
              >
                <path
                  fill="currentColor"
                  d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"
                />
              </svg>
            </span>

            <p className="text-ellipsis truncate">
              {`${liveMatchInfo.venue}, ${liveMatchInfo.city}`}
            </p>
          </div>

          <div className="max-w-xs">
            <Link
              href={`${LIVE_MATCH_URL}/${liveMatchInfo.slug}`}
              role="button"
              className="inline-flex items-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-red-700 dark:text-green-600 bg-white hover:bg-red-50 dark:hover:bg-green-100 sm:px-8 animate-bounce"
            >
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                className="-ml-1 mr-2 h-5 w-5"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.04248 5.24925C5.69572 5.24925 5.24533 5.40974 4.60144 5.8272C4.85706 6.12897 5.12783 6.45156 5.66298 7.06831C6.03565 7.49776 6.4118 7.9249 6.69591 8.23414C6.69836 8.23685 6.69945 8.23769 6.70184 8.24033L8.69305 7.08456L7.74868 6.17267C7.02088 5.56655 6.54702 5.27896 6.11041 5.25144C6.08818 5.25003 6.06556 5.24925 6.04242 5.24925H6.04248ZM13.782 6.22133C13.725 6.22069 13.6681 6.22095 13.6117 6.22198C12.9375 6.23442 12.3097 6.36571 11.8881 6.57157L7.55281 9.08769C7.71072 9.12276 7.86766 9.16478 8.02248 9.21789C9.13591 9.59965 10.1554 10.3924 11.2173 11.4544L25.1675 25.2152L30.3868 22.3513L16.6637 7.17879C16.1058 6.67347 15.2142 6.34682 14.2987 6.25105C14.1262 6.233 13.9531 6.22333 13.7819 6.2214L13.782 6.22133ZM3.75066 6.62191C2.61996 7.95757 2.42486 9.01867 2.76285 10.296C2.80745 10.4647 2.95988 10.7057 3.17361 10.9708C3.31508 10.7682 3.46687 10.5645 3.63032 10.3599C3.88749 10.038 4.17076 9.76806 4.47369 9.55053L4.46686 9.53764L4.64527 9.43387C4.73254 9.37831 4.82122 9.32688 4.91146 9.27964L5.67639 8.83555C5.4073 8.53791 5.10018 8.18993 4.78674 7.82867C4.40286 7.38626 4.03058 6.95082 3.75072 6.62191H3.75066ZM15.616 7.48803L19.3811 11.4629C16.4107 11.1382 15.7208 12.3136 14.932 13.6672L11.2367 9.84483L15.616 7.48803ZM6.68392 10.134C6.6379 10.1329 6.59175 10.1329 6.54534 10.1341C6.09572 10.1457 5.62966 10.2667 5.11113 10.5134C4.91552 10.661 4.72506 10.8483 4.53686 11.0839C2.8759 13.1635 2.57426 14.8954 2.89433 16.4643C3.21434 18.0333 4.21794 19.4714 5.33814 20.7475C6.19627 21.7252 7.19825 22.2939 8.46231 22.5477C9.72649 22.8016 11.262 22.7222 13.1016 22.3237C15.2445 21.8597 17.3562 22.8664 19.2769 24.1068C20.9603 25.194 22.5431 26.4974 23.8518 27.3263L24.4183 26.1058L10.3985 12.2761L10.3971 12.2747C9.39649 11.2741 8.49151 10.6051 7.64607 10.3152C7.32252 10.2042 7.00638 10.1415 6.68392 10.1339V10.134ZM30.3434 23.6985L25.6093 26.2962L24.9338 27.7507L29.4992 25.2751L30.3433 23.6986L30.3434 23.6985Z"
                  fill="text-red-600"
                />
              </svg>
              Live Match Center
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default IndexLiveMatchHero;
