import { Fragment, useState } from "react";

import Link from "next/link";
import RightArrowIcon from "../../common/icons/RightArrowIcon";
import ProductCard from "./ProductCard";

import { Dialog, Transition } from "@headlessui/react";

const Shop = ({ products, shopUrl }) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return <>
    <section>
      <div className="max-w-7xl mx-auto pt-10 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-baseline sm:justify-between">
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
            Online Store
          </h2>

          <a
            target="_blank"
            role="button"
            className="hidden text-sm font-semibold text-red-600 dark:text-green-800 hover:text-red-500 dark:hover:text-green-700 sm:block"
            onClick={() => {
              openModal();
            }}
          >
            Browse all categories
            <span aria-hidden="true">
              <RightArrowIcon />
            </span>
          </a>
        </div>

        <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
          {products.map((product) => (
            <ProductCard product={product} key={product.name} />
          ))}
        </div>
        <div className="mt-6 sm:hidden">
          <Link
            href={shopUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="block text-sm font-semibold text-red-600 dark:text-green-800 hover:text-red-500 dark:hover:text-green-700">
            Browse all categories<span aria-hidden="true">
              <RightArrowIcon />
            </span>

          </Link>
        </div>
      </div>
    </section>

    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {}}
        open={isOpen}
      >
        <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Get your Kotoko Jerseys
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Kindly purchase your Kotoko home and away jerseys by
                    calling this number
                    <strong>
                      <a href="tel:0244653485"> 0244653485</a>
                    </strong>
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-start float-right rounded-md border border-transparent px-4 py-2 text-sm font-medium text-green-500 bg-green-100 hover:bg-green-200  focus:outline-none focus-visible:ring-2 focus-visible:ring-green-600  focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    Got it, thanks!
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  </>;
};

export default Shop;
