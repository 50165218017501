import useSWR from "swr";

import HeroNews from "../components/elements/index/HeroNews";
import { apiWrapper } from "../utils/api/apiWrapper";
import {
  kAsanteKotokoFeedURL,
  kUpcomingLiveMatchURL,
} from "../utils/constants/apiUrls";
import RecentNews from "../components/elements/index/RecentNews";
import RecentGallery from "../components/elements/index/RecentGallery";
import { NoDataFullPage } from "../components/common/NoData";
import KotokoTVBanner from "../components/elements/index/KotokoTVBanner";
import TableAndFixture from "../components/elements/index/TableAndFixture";
import Shop from "../components/elements/index/Shop";
import { GUEST_TOKEN } from "../utils/constants/names";
import {
  TEN_MINUTES_REFRESH_INTERVAL_SECONDS,
  FOURTY_MINUTE_REFRESH_INTERVAL_MILLISECONDS,
  TEN_MINUTE_REFRESH_INTERVAL_MILLISECONDS,
} from "../utils/constants/numbers";
import { customStaticSeoConfig } from "../utils/helpers/helpers";
import IndexLiveMatchHero from "../components/elements/index/IndexLiveMatchHero";

export default function Home({ liveMatch, feed }) {
  const token = process.env.GUEST_USER_TOKEN
    ? process.env.GUEST_USER_TOKEN
    : GUEST_TOKEN;

  const { data: liveMatchData } = useSWR([kUpcomingLiveMatchURL, token], {
    fallbackData: liveMatch,
    refreshInterval: FOURTY_MINUTE_REFRESH_INTERVAL_MILLISECONDS,
    focusThrottleInterval: FOURTY_MINUTE_REFRESH_INTERVAL_MILLISECONDS,
  });

  const { data: feedData } = useSWR([kAsanteKotokoFeedURL, token], {
    fallbackData: feed,
    refreshInterval: TEN_MINUTE_REFRESH_INTERVAL_MILLISECONDS,
    focusThrottleInterval: TEN_MINUTE_REFRESH_INTERVAL_MILLISECONDS,
  });

  const {
    latest_news: latestNews,
    latest_galleries: latestGalleries,
    shop_categories: shopCategories,
    last_match: lastMatch,
    next_match: nextMatch,
    gpl_table: gplTable,
    kotoko_tv_url: kotokoTvUrl,
    shop_website_url: shopWebsiteUrl,
    ticket_website_url: ticketWebsiteUrl,
  } = feedData?.data;

  const { live_match: liveMatchInfo, live_match_images: liveMatchImages } =
    liveMatchData?.data;

  const noLiveMatchHeroNews = latestNews[0];
  const noLiveMatchNews = latestNews.slice(1);
  const liveMatchNews = latestNews.slice(0, 3);
  const { live_tv_url: liveTvUrl } = kotokoTvUrl;
  const { shop_url: shopUrl } = shopWebsiteUrl;
  const { ticket_website_url: ticketUrl } = ticketWebsiteUrl;

  const isGPLTableEmpty = gplTable.length === 0;

  const upcomingLiveMatch = Object.keys(liveMatchData?.data).length !== 0;

  let top4Teams = [];

  if (!isGPLTableEmpty) {
    const top4 = gplTable.slice(0, 4);
    const kotokoNotInTop4 = top4.find(
      (teamStat) =>
        teamStat.team === "Asante Kotoko" ||
        teamStat.team === "Kotoko" ||
        teamStat.team === "Asante Kotoko SC"
    );

    /// IF KOTOKO IS NOT IN TOP4
    if (!kotokoNotInTop4) {
      let kotokoPosition =
        gplTable.findIndex((teamStat) => teamStat.team === "Asante Kotoko") ??
        gplTable.findIndex((teamStat) => teamStat.team === "Kotoko") ??
        gplTable.findIndex((teamStat) => teamStat.team === "Asante Kotoko SC");

      let kotoko =
        gplTable.find((teamStat) => teamStat.team === "Asante Kotoko") ??
        gplTable.find((teamStat) => teamStat.team === "Kotoko") ??
        gplTable.find((teamStat) => teamStat.team === "Asante Kotoko SC");

      const newTop4 = [];

      top4.forEach((element, index) =>
        newTop4.push({
          position: index + 1,
          id: element["id"],
          team: element["team"],
          team_logo: element["team_logo"],
          matches_played: element["matches_played"],
          matches_won: element["matches_won"],
          matches_drawn: element["matches_drawn"],
          matches_lost: element["matches_lost"],
          goals_scored: element["goals_scored"],
          goals_against: element["goals_against"],
          goal_difference: element["goal_difference"],
          team_point: element["team_point"],
        })
      );

      newTop4.push({
        position: kotokoPosition + 1,
        id: kotoko["id"],
        team: kotoko["team"],
        team_logo: kotoko["team_logo"],
        matches_played: kotoko["matches_played"],
        matches_won: kotoko["matches_won"],
        matches_drawn: kotoko["matches_drawn"],
        matches_lost: kotoko["matches_lost"],
        goals_scored: kotoko["goals_scored"],
        goals_against: kotoko["goals_against"],
        goal_difference: kotoko["goal_difference"],
        team_point: kotoko["team_point"],
      });

      top4Teams = newTop4;
    }

    /// IF KOTOKO IS IN TOP4
    if (kotokoNotInTop4) {
      const newTop4 = [];

      top4.forEach((element, index) =>
        newTop4.push({
          position: index + 1,
          id: element["id"],
          team: element["team"],
          team_logo: element["team_logo"],
          matches_played: element["matches_played"],
          matches_won: element["matches_won"],
          matches_drawn: element["matches_drawn"],
          matches_lost: element["matches_lost"],
          goals_scored: element["goals_scored"],
          goals_against: element["goals_against"],
          goal_difference: element["goal_difference"],
          team_point: element["team_point"],
        })
      );
      top4Teams = newTop4;
    }
  }

  return (
    <>
      {/* SEO HEAD  */}
      {customStaticSeoConfig()}

      {latestNews.length !== 0 && (
        <main>
          {/* HERO NEWS  */}

          {upcomingLiveMatch ? (
            <IndexLiveMatchHero
              liveMatchInfo={liveMatchInfo.fixture}
              liveMatchImage={liveMatchImages[0].image}
            />
          ) : (
            <HeroNews article={noLiveMatchHeroNews} />
          )}

          {/* RECENT NEWS  */}
          {upcomingLiveMatch ? (
            <RecentNews NewsList={liveMatchNews} />
          ) : (
            <RecentNews NewsList={noLiveMatchNews} />
          )}

          {/* RECENT GALLERY  */}
          <RecentGallery galleries={latestGalleries} />

          {/* TV BANNER  */}
          <KotokoTVBanner kotokoTvUrl={liveTvUrl} />

          {/* MATCHES AND TABLE  */}
          {!isGPLTableEmpty && (
            <TableAndFixture
              lastMatch={lastMatch}
              nextMatch={nextMatch}
              top4Table={top4Teams}
              ticketWebsiteUrl={ticketUrl}
            />
          )}

          {/* ONLINE SHOP  */}
          {shopCategories && (
            <Shop products={shopCategories} shopUrl={shopUrl} />
          )}
        </main>
      )}

      {latestNews.length === 0 && <NoDataFullPage />}
    </>
  );
}

export const getStaticProps = async (context) => {
  const token = process.env.GUSET_USER_TOKEN
    ? process.env.GUSET_USER_TOKEN
    : GUEST_TOKEN;

  const liveMatch = await apiWrapper.get(kUpcomingLiveMatchURL, token);

  const feedData = await apiWrapper.get(kAsanteKotokoFeedURL, token);

  return {
    props: {
      liveMatch: liveMatch,
      feed: feedData,
    },
    revalidate: TEN_MINUTES_REFRESH_INTERVAL_SECONDS,
  };
};
