import Link from "next/link";
import GPLTable from "./GPLTable";
import RightArrowIcon from "../../common/icons/RightArrowIcon";
import LastMatch from "./LastMatch";
import NextMatch from "./NextMatch";
import { STANDINGS_URL } from "../../../utils/constants/websiteUrls";

const TableAndFixture = ({
  lastMatch,
  nextMatch,
  top4Table,
  ticketWebsiteUrl,
}) => {
  const isLastMatchEmpty = lastMatch.date_and_time === null;
  const isNextMatchEmpty = nextMatch.date_and_time === null;

  return (
    <section className="mt-10">
      <div className="max-w-7xl mx-auto pt-10 px-4 sm:pt-10 sm:px-6 lg:px-8 ">
        <div className="bg-green-700 dark:bg-red-600 py-10 px-5 rounded-md">
          <div className="flex flex-col-reverse  sm:flex-col-reverse md:flex-col-reverse lg:flex-row xl:flex-row 2xl:flex-row  justify-between items-center">
            {!isLastMatchEmpty && <LastMatch lastMatch={lastMatch} />}

            {!isNextMatchEmpty && (
              <NextMatch
                nextMatch={nextMatch}
                ticketWebsiteUrl={ticketWebsiteUrl}
              />
            )}
          </div>

          <div className="px-4">
            <div className="flex justify-end mt-10 mb-0">
              <Link
                href={STANDINGS_URL}
                className="text-sm font-semibold text-green-100 dark:text-red-100 hover:text-green-50 dark:hover:text-red-50 sm:block">
                View full table<span aria-hidden="true">
                  <RightArrowIcon />
                </span>

              </Link>
            </div>
            <GPLTable gplTable={top4Table} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TableAndFixture;
